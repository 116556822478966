import { createContext, useContext, useState, useCallback } from "react";

const ActivatorContext = createContext();

const ActivatorProvider = ({ children }) => {
    const [callables, setCallables] = useState({});



    const addActivatorCallable = (callableName, newCallable) => {
        const tmp = callables[callableName] ?? []
        const originalCallables = callables

        tmp.push(newCallable)
        originalCallables[callableName] = tmp
        console.log("addActivatorCallable new value", originalCallables)
        
        setCallables(originalCallables)
    }

    const call = (callableName) => {

        console.log("ActivatorContect:call", callableName, callables)
        if (!callables[callableName]) {
            return;
        }

         callables[callableName].map(cb => cb());

    }

    return (
        <ActivatorContext.Provider value={{ addActivatorCallable, call }}>{children}</ActivatorContext.Provider>
    );
};

export const useActivator = () => {
    const {addActivatorCallable, call}  = useContext(ActivatorContext)
    return {
        addActivatorCallable: addActivatorCallable, 
        call: call
    }
};

export default ActivatorProvider;