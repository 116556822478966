import React, { Fragment } from 'react'
import { Module } from '../Module'

const DrawerActivator = ({ drawerTitle, openButtonLabel, openButtonIcon, openButtonVariant, action,
    filters, defaultValue, onListValuesChange, dataProviderName, showDetail, enableSelection }) => {

    return (
        <Fragment>
            <Module
                withDrawer
                drawerTitle={drawerTitle}
                openButtonLabel={openButtonLabel ?? drawerTitle}
                openButtonIcon={openButtonIcon}
                openButtonVariant={openButtonVariant}
                action={action}
                showDetail={showDetail}
                dataProviderName={dataProviderName}
                filters={filters}
                defaultValue={defaultValue}
                onListValuesChange={onListValuesChange}
                enableSelection={enableSelection} />
        </Fragment>
    )

}

export default DrawerActivator


