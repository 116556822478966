const Main = (props) => {

    return (
        <main>
            {props.children}
        </main>
    );
}

export default Main;
