import React, { useState, useEffect } from 'react';

const AppContext = React.createContext({
    currentModuleName: 'noname',
    setCurrentModuleName: () => { },
});

export const AppContextProvider = ({ children, concreteconfig }) => {

    const [currentModuleName, setCurrentModuleName] = useState();
    const config = {
        currentModuleName,
        setCurrentModuleName,
        ...concreteconfig
    }





    // useEffect(() => {
    //     setConfig({ 
    //         ...config,
    //         ...concreteconfig, 
    //     });
    // }, [concreteconfig]);


    // inizializzo in contesto
    // useEffect(() => {
    //     setConfig({ ...concreteconfig, currentModuleName: currentModuleName, setCurrentModuleName: setCurrentModuleName });
    // }, [concreteconfig, currentModuleName]);

    // // quando cambia il modulo
    // useEffect(() => {
    //     setConfig({ ...config, currentModuleName: currentModuleName, setCurrentModuleName: setCurrentModuleName });
    // }, [config, currentModuleName]);

    // quando si apre un drawer
    // useEffect(() => {
    //     setConfig({...config, currentOpenDrawers: currentOpenDrawers, setCurrentOpenDrawers: setCurrentOpenDrawers});
    // }, [currentOpenDrawers]);

    return (
        <AppContext.Provider value={config}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContext;