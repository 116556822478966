import logoasset from '../assets/logo.svg';
import { AppContextProvider } from '../contexts';
import { ContextualizedApp } from '../app';
import '../../mtska-frontend-app-component/styles/components.scss';
import { Provider } from '../..//mtska-frontend-data-provider';
import { EmptyModule, RoleModule } from '../../mtska-frontend-modules';

const App = ({ concreteconfig }) => {

    const config = {
        title: 'Scaffolding',
        logo: logoasset,
        modules: [
            EmptyModule,
            RoleModule
        ],
        copyright: '© MyFleetSolution',
        privacy: {
            label: 'Privacy policy',
            url: 'https://www.myfleetsolution.it'
        },
        auth0: {
            domain: "dev-aiv2g0oy6k7dfpmu.us.auth0.com",
            clientId: "BldHtjyVwZiN0ovlQI7adMFVcW4x0ENB",
            authorizationParams: {
                redirect_uri: "https://executive-consecution.evelean.com/callback",
            }
        },
        ...concreteconfig
    }

    const mergeDataProviders = () => {
        const dps = [{
            name: 'default'
        }];
        config.modules.map((m) => {
            if (!Array.isArray(m.dataProviders)) return
            return m.dataProviders.map((dp) => {
                return dps.push(dp)
            })
        })
        return dps;
    }

    return (
        <Provider config={mergeDataProviders()}>
            <AppContextProvider concreteconfig={config}>
                <ContextualizedApp />
            </AppContextProvider>
        </Provider>
    )
}

export default App;