import React from "react"

import { FormControl, FormHelperText, FormLabel, RadioGroup, Radio } from "@mui/joy";
import { Controller } from "react-hook-form";

const InputRadio = (props) => {
    const { editing, required, name, control, label, autoFocus, type = "radio", hint, haserror, options, className = "" } = props
    let { disabled, readonly } = props;
    if (!editing) readonly = true;
    if (readonly) disabled = true;

    let generalClass = "form-element-control";
    generalClass += (editing) ? " editing" : " reading";
    generalClass += (haserror) ? " error" : "";
    generalClass += (required) ? " required" : "";
    generalClass += (disabled) ? " disabled" : "";
    generalClass += (readonly) ? " readonly" : "";

    const generateOptions = () => {
        return options.map((option, k) => (
            <Radio 
                key={k} 
                value={option.value} 
                label={option.label} 
                disabled={disabled}
            />
        ));
    };

    return (

        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value, name },
                fieldState: { error, invalid },
                formState,
            }) => (
                <div className={("form-element radio "+className).trim()}>
                    <FormControl error={invalid} className={(error) ? generalClass + " form-element-error" : generalClass}>
                        <FormLabel className="form-element-label" htmlFor={name}>{label}</FormLabel>
                        <RadioGroup
                            id={name}
                            type={type}
                            name={name}
                            value={value}
                            // error={invalid}
                            onChange={onChange}
                            variant="outlined"
                            autoComplete="off"
                            className="form-element-field"

                            autoFocus={autoFocus}
                            disabled={disabled}
                            readOnly={readonly}
                            required={required}
                        >
                            {generateOptions()}
                        </RadioGroup>
                        <FormHelperText className="form-element-hint">
                            {(haserror || error) ? (
                                <>
                                    {error ? error.message : 'Messaggio di errore'}
                                </>
                            ) : (
                                <>
                                    {hint}
                                </>
                            )}
                        </FormHelperText>
                    </FormControl>
                </div>
            )}
        />

    );
};


export default InputRadio;
