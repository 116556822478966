import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';


const Minimal = props => {
    const { children } = props;



    return (
        <div className="app-login">
            <main ><Outlet /></main>
        </div>
    );
};

export default Minimal;
