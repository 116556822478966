import Empty from "./Empty";

const Module = {
    name: "dashboard",
    Component: Empty,
    defaultRoutePath: "/dashboard",
    menuItem: {
        title: "Dashboard",
        href: "/dashboard",
        icon: "faChartNetwork"
    },
    routes: [
        {
            path: "/dashboard",
            protected: true,
            children: [
                {
                    index: true,
                    path: "/dashboard",
                    Component: Empty,
                }
            ],
        },
    ]
}

export default Module;
