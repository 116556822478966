import React from "react";
import { useLocation } from 'react-router-dom';
import { Box, List, ListItem } from '@mui/joy';
import { Anchor } from "../Typography";

const AppMenu = (props) => {
    const { modules } = props;
    const location = useLocation();
    
    return (
        <Box className="app-menu">
            <List className="app-menu-portion">
                {modules.map((module, k) => {
                    if (!module.hasOwnProperty('menuItem')) {
                        return;
                    }

                    return (
                        <ListItem key={k} className={(location.pathname == module.menuItem.href) ? 'app-menu-item app-menu-item-current' : 'app-menu-item'}>
                            <Anchor icon={module.menuItem.icon} href={module.menuItem.href} underline="none">
                                <span className="name">{module.menuItem.title}</span>
                            </Anchor>
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
}

export default AppMenu;