import React from "react"

import { FormControl, FormHelperText, FormLabel, Input, Typography } from "@mui/joy";
import { Controller } from "react-hook-form";
import { Icon } from '../../assets';

const InputEmail = (props) => {
    const { editing, required, name, control, label, autoFocus, placeholder, type = "email", hint, icon, haserror, disabled, className = "" } = props
    let { readonly } = props;
    if (!editing) readonly = true;

    let generalClass = "form-element-control";
    generalClass += (editing) ? " editing" : " reading";
    generalClass += (haserror) ? " error" : "";
    generalClass += (required) ? " required" : "";
    generalClass += (disabled) ? " disabled" : "";
    generalClass += (readonly) ? " readonly" : "";

    return (

        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value, name },
                fieldState: { error, invalid },
                formState,
            }) => (
                <div className={("form-element email "+className).trim()}>
                    <FormControl error={invalid} className={(error) ? generalClass + " form-element-error" : generalClass}>
                        <FormLabel className="form-element-label" htmlFor={name}>{label}</FormLabel>
                        <Input
                            id={name}
                            type={type}
                            name={name}
                            value={value}
                            error={invalid}
                            onChange={onChange}
                            variant="outlined"
                            autoComplete="off"
                            className="form-element-field"

                            autoFocus={autoFocus}
                            placeholder={placeholder}
                            disabled={disabled}
                            readOnly={readonly}
                            required={required}

                            startDecorator={(icon) ? (
                                <Icon icon={icon} />
                            ) : (
                                <></>
                            )}
                            endDecorator={(haserror || error) ? (
                                <Icon icon="faCircleExclamation" />
                            ) : (
                                <></>
                            )}
                        />
                        <FormHelperText className="form-element-hint">
                            {(haserror || error) ? (
                                <>
                                    {error ? error.message : 'Messaggio di errore'}
                                </>
                            ) : (
                                <>
                                    {hint}
                                </>
                            )}
                        </FormHelperText>
                    </FormControl>
                </div>
            )}
        />

    );
};

export default InputEmail;