import React from "react"

import { Link } from '@mui/joy';
import { Icon } from '../../assets';

const Anchor = ({ href, target = "_self", level = 'body-md', icon, iconfamily = 'fal', disabled, variant, underline, className, children }) => {
    className = (typeof className != typeof undefined) ? className+" mtska-link mtska-link-"+level : "mtska-link mtska-link-"+level;

    return (
        <Link 
            level={level} 
            target={target} 
            href={href} 
            disabled={disabled} 
            variant={variant} 
            underline={underline} 
            className={className}
            startDecorator={(icon) ? (
                <Icon icon={icon} family={iconfamily}/>
            ) : (
                <></>
            )}
        >
            {children}
        </Link>
    );
}

export default Anchor;