import React, { useState } from 'react';

import { Box, Chip } from '@mui/joy';
import { Text } from '../Typography';
import { Icon } from '../../assets';

const KPI = ({ title, score, icon, delta, scoreFormatStyle, scoreFormatUnit, scoreFormatCurrency }) => {

    let format = {
        style: scoreFormatStyle
    };

    if (scoreFormatStyle == 'currency') format.currency = (scoreFormatCurrency) ? scoreFormatCurrency : 'EUR';
    if (scoreFormatStyle == 'unit' && scoreFormatUnit) format.unit = scoreFormatUnit;

    return (
        <Box className="mtksa-chart mtksa-chart-kpi">
            <Box className="iconwrapper">
                <Icon icon={icon} family="fal"></Icon>
            </Box>
            <Box className="scorewrapper">
                <Text level="title-sm" className="title">{title}</Text>
                <Text level="title-lg" className="score">
                    {
                        score ?
                            new Intl.NumberFormat('it-IT', format).format(score)
                            : "..."
                    }
                </Text>
                {
                    delta ?
                        <Chip
                            className={(delta >= 0) ? "delta positive" : "delta negative"}
                            startDecorator={(delta >= 0) ? <Icon icon="faArrowUp" /> : <Icon icon="faArrowDown" />}
                        >
                            {delta}%
                        </Chip>
                        : null
                }
            </Box>
        </Box>
    );
}

export default KPI;