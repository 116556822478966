import React from 'react';
import { Box, List, ListItem } from '@mui/joy';

const CellPeople = ({ value }) => {
    const limit = 4;
    const length = Object.keys(value).length;
    let more = null;

    if (length > limit) {
        more = <ListItem className={'cell-people-list-item-count cell-people-list-item-count-more'}>
            <span>+{length - limit}</span>
        </ListItem>
    }

    return (
        <Box className={"list-element-cell cell-people"}>
            <List className="cell-people-list">
                {value.map((person, k) => {
                    if (k >= limit) return;
                    return (
                        <ListItem key={k} className={'cell-people-list-item'}>
                            <span className="name" title={person.firstname + " " + person.lastname}>{person.firstname.charAt(0) + person.lastname.charAt(0)}</span>
                        </ListItem>
                    );
                })}

                {more}
            </List>
        </Box>
    );
}

export default CellPeople