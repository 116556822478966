import React from "react";
import { Text } from "../Typography";

const ActiveAppName = ({ logo, title }) => {

    return (
        <div className="appname">
            <div className='logo'>
                <img src={logo} alt={title} />
            </div>
            <div className="name">
                <Text level="h1">{title}</Text>
            </div>
        </div>
    );
}

export default ActiveAppName;


