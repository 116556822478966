import BiViewer from './BiViewer';
import Detail from './Detail';

const Module = {
    name: "biviewer",
    Component: BiViewer,
    defaultRoutePath: "/biviewer",
    menuItem: {
        title: "Business Intelligence",
        href: "/biviewer",
        icon: "faGauge"
    },
    menuItems: {
        "biviewer": [
        ]
    },
    routes: [
        {
            path: "/biviewer",
            protected: true,
            children: [
                {
                    index: true,
                    path: ":id?",
                    Component: BiViewer,
                }
            ],
        },
    ],
    dataProviders: [
        {
            "name": "biviewer",
            "endpoint": "/api/bis/viewer",
        },
    ],
    views: {
        detail: () => Detail
    },
    editable: false
}

export default Module;
