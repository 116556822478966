import React, { useEffect, useState } from 'react';
import { Drawer } from '../Drawer';
import { useDataProvider } from '../../../mtska-frontend-data-provider';
import { Button } from '../Button';
import ModuleDataContainer from './ModuleDataContainer';
import AppContext from '../../../mtska-frontend-app-scaffolding/contexts/AppContext';
import { List } from '../List';
import { Form } from '../Form';
import { Box } from '@mui/joy'
import { useActivator } from '../../../mtska-frontend-modules/context';


const moduleId = Math.random().toString(16).slice(2);

const Module = ({
    withDrawer = false,
    drawerTitle,
    openButtonLabel,
    action,
    showDetail = false,
    title = 'Module title',
    dataProviderName,
    moduleType,
    filters = {},
    leftButtons = [],
    onListValuesChange = () => { },
    defaultValue,
    enableSelection
}) => {

    const [ListComponent, setListComponent] = useState(() => List)
    const [DetailComponent, setDetailComponent] = useState(() => Form)

    const { modules } = React.useContext(AppContext);
    const [moduleConfig, setModuleConfig] = useState()

    useEffect(() => {
        if (!Array.isArray(modules)) return;
        setModuleConfig(modules.find(el => el.name === dataProviderName))
    }, [modules, dataProviderName])

    useEffect(() => {

        if (typeof moduleConfig?.views?.detail === 'undefined') return
        setDetailComponent(moduleConfig?.views?.detail)
    }, [moduleConfig])

    const { save } = useDataProvider(dataProviderName);

    const [formValues, setFormValues] = useState();

    const handleSetFormValues = (values) => {
        if (JSON.stringify(values) !== JSON.stringify(formValues)) {
            setFormValues(values)
        }
    }

    const handleFormSave = () => {
        save(formValues)
    }

  
    const [listValues, setListValues] = useState();
    useEffect(() => {
        onListValuesChange(listValues)
    }, [listValues, onListValuesChange])

    const [openedDrawer, setOpenedDrawer] = useState('pppp')
    const handleOnSubmitted = () => {
        setOpenedDrawer(false)
    }

    const handleListSave = () => {
        setOpenedDrawer(false)
    }

    const { call } = useActivator();


    return (
        <Box className={"module module-" + moduleType}>
            <Drawer
                moduleId={moduleId}
                withDrawer={withDrawer}
                openedDrawer={openedDrawer}
                title={drawerTitle}
                openButtonLabel={openButtonLabel}
                action={((moduleConfig?.editable !== false) && showDetail) ?
                    <Button className="button-main" onClick={() => call(`form_submit_${moduleId}`)}>Salva</Button> : action}
            >
                <ModuleDataContainer
                    moduleId={moduleId}
                    ListComponent={ListComponent}
                    DetailComponent={DetailComponent}
                    showDetail={showDetail}
                    dataProviderName={dataProviderName}
                    title={title}
                    filters={filters}
                    defaultValue={defaultValue}
                    enableSelection={enableSelection}
                    setFormValues={handleSetFormValues}
                    setListValues={setListValues}
                    handleFormSave={handleFormSave}
                    handleListSave={handleListSave}
                    moduleType={moduleType}
                    onSubmitted={handleOnSubmitted}
                />

            </Drawer>
        </Box>
    )
}

export default Module;

