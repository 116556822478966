import React, { Fragment, useEffect, useState } from 'react'
import { Box } from '@mui/joy'
import { DataGrid } from '@mui/x-data-grid'
import { Cell } from '../ListElements'
import ListToolbar from './ListToolbar'
import { useDataProvider } from '../../../mtska-frontend-data-provider'


const listId = Math.random().toString(16).slice(2);

const List = ({
    dataProviderName,

    defaultSelectedValues,
    enableSelection,
    onValuesChange,

    leftButtons = [],
    rightButtons = [],

    className = "",
    limit = 7
}) => {

    const { items, total, loading, tableConfig, loadAll } = useDataProvider(dataProviderName);
    const [columns, setColumns] = useState([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });

    const [toolBarLeftButtons, settoolbarLeftButtons] = useState(leftButtons)
    const [toolBarRightButtons, settoolbarRightButtons] = useState(rightButtons)

    // FILTERS
    const [filterModel, setFilterModel] = useState({});

    // SORTING
    const [sortModel, setSortModel] = useState({});
console.log('il limite è di', limit);
    // PAGINATION
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: limit,
    });

    // LOAD DATA

    // Some API clients return undefined while loading
    // Following lines are here to prevent `rowCount` from being undefined during the loading
    const rowCountRef = React.useRef(total || 0);

    const rowCount = React.useMemo(() => {
        if (total !== undefined) {
            rowCountRef.current = total;
        }
        return rowCountRef.current;
    }, [total]);

    useEffect(() => {
        console.log(filterModel);
        loadAll && loadAll(filterModel, sortModel, paginationModel.page + 1, paginationModel.pageSize)
    }, [paginationModel, filterModel, sortModel])

    useEffect(() => {
        if (typeof tableConfig?.columnVisibilityModel !== 'undefined') {
            setColumnVisibilityModel(tableConfig.columnVisibilityModel);
        }
        if (Array.isArray(tableConfig?.columns)) {
            const columns = tableConfig.columns.map((cc) => {
                return {
                    flex: 1,
                    field: cc,
                    renderCell: (params) => {
                        const type = tableConfig.columnsRendered[params?.field]?.type
                        const fieldDataProviderName = tableConfig.columnsRendered[params?.field]?.providerName
                        return (
                            <Fragment>
                                <Cell
                                    type={type}
                                    dataProviderName={dataProviderName}
                                    fieldDataProviderName={fieldDataProviderName}
                                    field={params?.field}
                                    value={params.value?.value || params.formattedValue}
                                    row={params.row}
                                />
                            </Fragment>
                        )
                    }
                }
            })
            setColumns(columns)
        }
    }, [tableConfig])

    const [rowSelectionModel, setRowSelectionModel] = useState([]);

    useEffect(() => {
        if (!enableSelection) return
        const selectedIems = items.filter(el => rowSelectionModel.includes(el._id))
        onValuesChange && onValuesChange(selectedIems);
    }, [rowSelectionModel]);

    useEffect(() => {
        if (!enableSelection) return

        if (!Array.isArray(defaultSelectedValues)) {
            setRowSelectionModel([])
            return
        }
        const selectedIems = defaultSelectedValues.map(el => el._id)
        setRowSelectionModel(selectedIems)
        console.log("defaultSelectedValues", defaultSelectedValues)
        console.log("selectedIems", selectedIems)
    }, [defaultSelectedValues]);

    return (
        <Box className={(className+" list-system listy-system-" + listId).trim()}>
            <DataGrid
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                }
                slots={{
                    toolbar: ListToolbar
                }}
                slotProps={{
                    toolbar: {
                        leftButtons: toolBarLeftButtons,
                        rightButtons: toolBarRightButtons,
                    }
                }}
                disableRowSelectionOnClick
                checkboxSelection={enableSelection}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                getRowId={(row) => row._id}
                initialState={{
                    columns: {

                    },
                }}
                autoHeight={true}
                pagination
                columns={columns}
                rows={items ?? []}


                paginationMode="server"
                rowsPerPage={10}
                loading={loading}
                rowCount={rowCount}
                pageSizeOptions={[5, 10, 15, 20, 100]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}

                filterMode="server"
                onFilterModelChange={setFilterModel}

                sortingMode="server"
                onSortModelChange={setSortModel}


                className={"list-wrapper list-wrapper-" + listId}
            />
        </Box>
    )
}

export default List



