import React from "react"

import { Typography } from '@mui/joy';
import { Icon } from '../../assets';

const Text = ({ level, icon, className, children }) => {
    className = (typeof className != typeof undefined) ? className+" mtska-text mtska-text-"+level : "mtska-text mtska-text-"+level;

    return (
        <Typography  
            level={level} 
            className={className}
            startDecorator={(icon) ? (
                <Icon icon={icon} />
            ) : (
                <></>
            )}
        >
            {children}
        </Typography>
    );
}

export default Text;