//import '../styles/App/App.scss';
import React from "react";
import { useContext } from "react";
import { Auth0Provider } from '@auth0/auth0-react'
import { Routes } from '../routes';
import { AppContext } from '../contexts';
import { DrawerProvider } from '../../mtska-frontend-app-component';


const ContextualizedApp = () => {
    const { auth0, modules } = useContext(AppContext);

    return (
            <DrawerProvider >
                <Auth0Provider
                    domain={auth0.domain}
                    clientId={auth0.clientId}
                    authorizationParams={auth0.authorizationParams}>
                    <Routes modules={modules} />
                </Auth0Provider>
            </DrawerProvider>
    )

}

export default ContextualizedApp;
