
const handleResponse = (response) => {

    if (response.status === 401) {
        // userService.logout();
        localStorage.removeItem('token');
        //  window.location.reload(true);
    }

    return Promise.resolve(response.data)


}

export default handleResponse