import React from "react"

import { Text } from "../Typography";
import { DialogTitle, ModalClose, Box } from "@mui/joy";

const DrawerHeader = (props) => {
    const { icon, children, action } = props;
    
    return (
        <Box className="drawer-section-header" >
            <ModalClose />
            <Text level="title-md" icon={icon} >
                {children}
            </Text>
            {action}
        </Box>
    );
};


export default DrawerHeader;
