import React, { useEffect, useState, useContext, Fragment } from 'react';
import { Box, Drawer as JoyDrawer } from '@mui/joy';
import { Fade } from '@mui/material';
import { Button } from '../Button';
import DrawerHeader from './DrawerHeader';
import { useDrawerContext } from './DrawerContext';
import { useActivator } from '../../../mtska-frontend-modules/context';



export default function Drawer({ moduleId, withDrawer, title, children, openButtonLabel, openButtonIcon, openButtonVariant, action }) {



    const [drawerId, setDrawerId] = useState(Math.random().toString(16).slice(2));

    const { currentOpenDrawers, add, remove, isLast  } = useDrawerContext();
    const [fullwidth, setFullwidth] = useState(false);
    const [active, setActive] = useState(false);
    const [opened, setOpened] = useState(false);
    const [fadeIn, setFadeIn] = useState(false)

    useEffect(() => {
        if (!active || !opened) return;

        if (isLast(drawerId)) {
            setFullwidth(false);
        } else {
            setFullwidth(true);
        }
       
    }, [currentOpenDrawers])

    useEffect(() => {
        if (!active) return;
        if (opened === true) {
            add(drawerId)
        } 
        if (opened === false) {
            remove(drawerId)
        }
    }, [opened])

    useEffect(() => {
        setOpened(active)
    }, [active]);

    const handleOpen = () => {
        setOpened(true);
    };

    const handleClose = () => {
        setFadeIn(false);
        setOpened(false);
    };

    const handleTransationEnd = () => {
        console.log("handleTransationEnd", opened)
        if (opened) {
            setFadeIn(true)
        } else {
            setActive(false)
        }
    }

    const { addActivatorCallable } = useActivator();
    useEffect(() => {
        addActivatorCallable(`drawer_close_${moduleId}`, () => handleClose())
    }, [])

    return withDrawer ? (
        <Box className={"drawer-system drawer-system-" + drawerId}>
            <Button size="medium" onClick={() => setActive(true)} label={openButtonLabel} icon={openButtonIcon} variant={openButtonVariant ?? 'outlined'} className="relationship-activator" />
            {active && (
                <JoyDrawer
                    disableAutoFocus
                    onTransitionEnd={handleTransationEnd}
                    open={opened}
                    onClose={handleClose}
                    anchor="right"
                    className={"drawer-wrapper drawer-wrapper-" + drawerId + " drawer-wrapper-fullwidth-" + fullwidth}
                >
                    <DrawerHeader action={action}>{title}</DrawerHeader>
                    <Fade in={fadeIn}>
                        <Box className="drawer-section-content">
                            {opened && children}
                        </Box>
                    </Fade>
                </JoyDrawer>
            )}
        </Box>
    ) : (
        <Fragment>{children}</Fragment>
    )
}