import React from 'react';
import { Box } from '@mui/joy';
import Auth0LogoutButton from '../../../mtska-frontend-modules/modules/Auth/views/Auth0/Auth0LogoutButton';
import { useDataProvider } from '../../../mtska-frontend-data-provider';

const UserMenu = () => {

    const { item } = useDataProvider('auth');

    return (
        <Box className="user-menu">
            <Box className="user-icon">{item?.data?.firstname.charAt(0)+item?.data?.lastname.charAt(0)}</Box>
            <Box className="user-name">{item?.data?.firstname+" "+item?.data?.lastname}</Box>
            <Box className="user-email">{item?.data?.email}</Box>
            <Auth0LogoutButton className="user-logout"></Auth0LogoutButton>
        </Box>
    )
}

export default UserMenu
