import React, { useState, useEffect } from 'react';
import { Button } from '../../../mtska-frontend-app-component';

const Detail = ({ title, label, icon = "", fields, values = {}, validation = {}, onValuesChange, save }) => {

    const [defaultValues, setDefaultValues] = useState({})
    const [uid, setUid] = useState(Date.now())

    useEffect(() => {
        setDefaultValues(values)
    }, [values])




    return (
        <div style={{ height: "100vh", width: "100%" }}>
            <Button 
                onClick={(e) => {
                    const el = document.getElementById(uid);
                    console.log('manda a full screen ', el);
                    if (el) el.requestFullscreen();
                }} 
                className="drawer-main-action">
                Full screen
                </Button>
            <iframe width="100%" height="100%" src={defaultValues?.url_embed}
                frameborder="0"
                style={{}}
                allowfullscreen
                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
                id={uid}
            >
            </iframe>
        </div>
    )

}

export default Detail;


