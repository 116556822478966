import React from 'react';
import { Module } from '../../../mtska-frontend-app-component';

const LegalEntity = ({ dataProviderName = 'legalentities' }) => {

   return <Module dataProviderName={dataProviderName} moduleType="legalentity" />

}

export default LegalEntity;


