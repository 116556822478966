
import createService from './inc/service'
import createReducer from './inc/reducer'
import createConstants from './inc/constants'
import createActions from './inc/actions'
import { combineReducers } from 'redux'
import { applyMiddleware, compose, createStore as reduxCreateStore, } from 'redux';
import { thunk } from 'redux-thunk';


const reducers = {}
const actions = {}
let store

const createDataProvider = (name, endpoint) => {
    const service = createService(endpoint)
    const constants = createConstants(name)
    const reducer = createReducer(constants)
    const action = createActions(constants, service)

    reducers[name] = reducer.reducer
    actions[name] = action

    return createStore()
}

const createStore = (config = []) => {
    config.map((cnf) => {
        createDataProvider(cnf.name, cnf.endpoint)
    })
    const rootReducer = combineReducers(reducers);
    const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose
    const enhancer = composeEnhancers(
        applyMiddleware(
            thunk,
        )
    );

    const persistedState = loadState();

    store = reduxCreateStore(
        rootReducer,
        persistedState,
        enhancer,
    )

    store.subscribe(() => {
        saveState(store.getState());
      });

    return store
}

const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch {
        // ignore write errors
    }
};

const useDataProviderActions = (name) => {
    return actions[name]
}

export { createStore, createDataProvider, useDataProviderActions }

