import Role from './Role';

const Module = {
    name: "roles",
    Component: Role,
    defaultRoutePath: "/roles",
    menuItem: {
        title: "Roles",
        href: "/roles",
        icon: "faUserTag"
    },
    menuItems: {
        "roles": [
        ]
    },
    routes: [
        {
            path: "/roles",
            protected: true,
            children: [
                {
                    index: true,
                    path: ":id?",
                    Component: Role,
                }
            ],
        },
    ],
    dataProviders: [
        {
            "name": "roles",
            "endpoint": "/api/auth/role",
        },
        // {
        //   "name": "person",
        //   "endpoint": "/api/persons/person",
        // },
        // {
        //   "name": "bis",
        //   "endpoint": "/api/bis/bi"
        // }
    ]
}

export default Module;
