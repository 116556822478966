import React from 'react';
import { Module } from '../../../mtska-frontend-app-component';

const Role = ({ dataProviderName = 'roles' }) => {

   return <Module dataProviderName={dataProviderName} />

}

export default Role;


