import LegalEntity from './LegalEntity';

const Module = {
    name: "legalentities",
    Component: LegalEntity,
    defaultRoutePath: "/legalentities",
    menuItem: {
        title: "Legal Entities",
        href: "/legalentities",
        icon: "faBuilding"
    },

    menuItems: {
        "legalentities": [
        ]
    },
    routes: [
        {
            path: "/legalentities",
            protected: true,
            children: [
                {
                    index: true,
                    path: ":id?",
                    Component: LegalEntity,
                }
            ],
        },
    ],
    dataProviders: [
        {
            "name": "legalentities",
            "endpoint": "/api/companies/legalentity",
        },
       
    ]
}

export default Module;
