
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton as JoyIconButton } from "@mui/joy"
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';

export default function IconButton({onClick, className = '', icon, family = 'fal'}) {
    
    return (
        <JoyIconButton
            onClick={onClick}
            className={className}
        >
            <FontAwesomeIcon icon={(family == 'fal') ? fal[icon] : fas[icon]} />
        </JoyIconButton>
    );
}
