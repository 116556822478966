
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';

export default function Icon({icon, family = 'fal'}) {
    
    return (
        <FontAwesomeIcon icon={(family == 'fal') ? fal[icon] : fas[icon]} />
    );
}
