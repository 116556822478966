import React from "react";
import { Box } from '@mui/joy';
import { IconButton } from "../../../mtska-frontend-app-component";

const Minimizer = ({onClick, visible, open}) => {

    return (
        <Box className={(visible) ? "aside-minimizer" : "aside-minimizer hidden"}>
            <IconButton icon={(open) ? "faArrowLeftFromLine" : "faArrowRightFromLine"} onClick={onClick} family="fas" />
        </Box>
    );
}

export default Minimizer;