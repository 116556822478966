import React from "react"
import InputText from "./InputText";
import InputTextarea from "./InputTextarea";
import InputDate from "./InputDate";
import InputEmail from "./InputEmail";
import InputNumber from "./InputNumber";
import InputSelect from "./InputSelect";
import InputRadio from "./InputRadio";
import InputCheckbox from "./InputCheckbox";
import InputSwitch from "./InputSwitch";
import InputRelationship from "./InputRelationship";

const Input = (props) => {

    if (props.type === 'text')          return <InputText {...props} />
    if (props.type === 'textarea')      return <InputTextarea {...props} />
    if (props.type === 'number')        return <InputNumber {...props} />
    if (props.type === 'date')          return <InputDate {...props} />
    if (props.type === 'email')         return <InputEmail {...props} />
    // if (props.type === 'relation')      return <InputList {...props} />
    if (props.type === 'select')        return <InputSelect {...props} />
    if (props.type === 'radio')         return <InputRadio {...props} />
    if (props.type === 'checkbox')      return <InputCheckbox {...props} />
    if (props.type === 'switch')        return <InputSwitch {...props} />
    if (props.type === 'relationship')  return <InputRelationship {...props} />

};

export default Input
