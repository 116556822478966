import React from 'react'
import { Box } from '@mui/joy';
import { DrawerActivator } from '../Drawer'

const CellDrawer = (props) => {

    return (
        <Box className={"list-element-cell cell-drawer"}>
            <DrawerActivator {...{...props, openButtonVariant:"small", drawerTitle:"Item Detail" }} />
        </Box>
    )
}

export default CellDrawer


