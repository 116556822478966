import React from 'react';

import { Box } from '@mui/joy';
import { Anchor } from '../Typography';

const CellEmail = ({ value }) => {
    return <Box className={"list-element-cell cell-email"}>
        <Anchor
            href={"mailto:" + value}
            icon="faEnvelope"
            target="_blank"
        >
            {value}
        </Anchor>
    </Box>
}

export default CellEmail