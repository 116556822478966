import React from "react";
import { Text } from '../Typography';

const ActiveModuleName = ({currentModuleName}) => {    
    return (
        <div className="modulename">
            <Text level="h2">{currentModuleName}</Text>
        </div>
    );
}

export default ActiveModuleName;