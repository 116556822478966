import React from "react";
import { AppContext } from '../../contexts';
import { Text, Anchor } from '../../../mtska-frontend-app-component';

const Footer = (props) => {
    const { copyright, privacy } = React.useContext(AppContext);

    return (
        <footer>
            <div className="copyright"><Text>{copyright}</Text></div>
            <div className="privacy"><Anchor component="a" href={privacy.url} target="_blank">{privacy.label}</Anchor></div>
        </footer>
    );
}

export default Footer;