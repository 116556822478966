import { React, createContext, useContext, useState, useEffect } from "react";

const DrawerContext = createContext();
export default DrawerContext;


export const DrawerProvider = ({ children }) => {

    const [currentOpenDrawers, setCurrentOpenDrawers] = useState([]);
    useEffect(() => {
    }, [currentOpenDrawers])

    const isLast = (drawerId) => {
        return currentOpenDrawers.at(-1) === drawerId;
    }

    const add = (drawerId) => {
        setCurrentOpenDrawers([...currentOpenDrawers, drawerId]);
    }

    const remove = (drawerId) => {
        const stack = [...currentOpenDrawers];
        const index = stack.indexOf(drawerId);
        if (index > -1) {
            stack.splice(index, 1);
        }

        setCurrentOpenDrawers([...stack]);
    }

    return (
        <DrawerContext.Provider value={{ currentOpenDrawers, add, remove, isLast }}>
            {children}
        </DrawerContext.Provider>
    );
};


export const useDrawerContext = () => {
    const context = useContext(DrawerContext);
    if (context === undefined) {
        throw new Error("useDrawerContext must be used within a DrawerProvider");
    }
    return {
        add: context.add,
        currentOpenDrawers: context.currentOpenDrawers,
        remove: context.remove,
        isLast: context.isLast,

    };
};

