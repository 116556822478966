import {Provider as ReduxProvider} from 'react-redux'
import { createStore } from './dataProvider'

const Provider = ({config =[], store,  children}) => {
    if (config.length === 0 && !store) {
        return <>{children}</>
    }
    const storeConfig = createStore(config);
    return <ReduxProvider store={config.length === 0 ? store : storeConfig}>{children}</ReduxProvider>
}

export default Provider