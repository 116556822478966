//// LOCAL
import './mtska-frontend-app-scaffolding/styles/App/App.scss';
import { App } from './mtska-frontend-app-scaffolding';
import { EmptyModule, RoleModule, PersonModule, BiModule, LegalEntityModule, BiViewerModule, AuthModule } from './mtska-frontend-modules';
import ActivatorProvider from './mtska-frontend-modules/context/AcrtivatorContext';

//// PROD
// import '@BluedogSrl/mtska-frontend-app-scaffolding/dist/ems/index.css';
// import { App,  NoModule } from '@BluedogSrl/mtska-frontend-app-scaffolding';
// import { EmptyModule, RoleModule, PersonModule } from '@BluedogSrl/mtska-frontend-modules';


const AppExecutive = () => {
    const config = {
        title: 'Executive',
        modules: [
            AuthModule,
            EmptyModule,
            BiViewerModule,
            BiModule,
            LegalEntityModule,
            PersonModule
        ],
        copyright: '© MyFleetSolution',
        privacy: {
            label: 'Privacy policy',
            url: 'https://www.myfleetsolution.it'
        },
        auth0: {
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            clientId: process.env.REACT_APP_AUTH0_CLIENTID,
            authorizationParams: {
                redirect_uri: process.env.REACT_APP_AUTH0_AUTHORIZATIONPARAMS_REDIRECT_URI,
            }
        }
    }

    return (
        <ActivatorProvider>
         <App concreteconfig={config} />
        </ActivatorProvider>
    )
}

export default AppExecutive;
