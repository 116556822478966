import React from 'react';
import { Module } from '../../../mtska-frontend-app-component';

const Bi = ({ dataProviderName = 'bis' }) => {

   return <Module dataProviderName={dataProviderName} moduleType="bi"/>

}

export default Bi;


