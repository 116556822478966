import React from 'react';
import { Module } from '../../../mtska-frontend-app-component';

const Person = ({ dataProviderName = 'persons' }) => {

   return <Module dataProviderName={dataProviderName} moduleType="person"/>

}

export default Person;


