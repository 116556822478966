import { KPI, List, Text } from '../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../mtska-frontend-data-provider';
import { Box } from '@mui/joy';
import React from 'react';

const Empty = () => {

    const { widgets } = useDataProvider('persons');

    return (
        <Box className="module-body">
            <Box className="mtksa-chart-wrapper">
                <KPI
                    title="Report BI"
                    icon="faDashboard"
                    score={widgets.bi?.total}>
                </KPI>
                <KPI
                    title="People"
                    icon="faPeople"
                    score={widgets.person?.total}>
                </KPI>
                <KPI
                    title="Legal entities"
                    icon="faBuilding"
                    score={widgets.legalentity?.total}>
                </KPI>
            </Box>

            <Text level="h3" className="module-body-section-title">Executive Application</Text>
            <Text level="body-md" className="module-body-section-subtitle">Benvenuto nella Dashboard dell'Executive App di Consecution Ecosystem. Qui puoi trovare le principali informazioni relative ai moduli attivi nel servizio.</Text>
            
            <Box className="mtksa-chart-wrapper">
                <List dataProviderName={'bis'} className='mtksa-chart' limit={5}></List>
                <List dataProviderName={'persons'} className='mtksa-chart' limit={5}></List>
                <List dataProviderName={'legalentities'} className='mtksa-chart' limit={5}></List>
            </Box>
        </Box>
    );
};

export default Empty;
