import React from 'react';

import { Box } from '@mui/joy';
import { Text } from '../Typography';
import { Icon } from '../../assets';
import { useDataProvider } from '../../../mtska-frontend-data-provider';

const User = ({ title, icon  }) => {
    const { item } = useDataProvider('auth');

    return (
        <Box className="mtksa-chart mtksa-chart-user">
            <Box className="iconwrapper">
                <Icon icon={icon} family="fal"></Icon>
            </Box>
            <Box className="userwrapper">
                <Text level="title-sm" className="title">{title}</Text>
                <Text level="title-lg" className="name">{item?.data?.firstname+" "+item?.data?.lastname}</Text>
                <Text level="title-sm" className="email">{item?.data?.email}</Text>
            </Box>
        </Box>
    );
}

export default User;