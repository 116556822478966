import React, { useState } from 'react'
import { Box } from '@mui/joy'
import { IconButton } from '../../assets'
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid'

const ListToolbar = ({ leftButtons = [], rightButtons = [] }) => {




    const [settingsOpened, setSettingsOpened] = useState(false)
    const handleSettingsToggle = () => {
        setSettingsOpened(!settingsOpened)
    }

    return (
        <GridToolbarContainer className="list-toolbar">
            {settingsOpened ? (
                <Box className="list-toolbar-secondary list-toolbar-option">
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                </Box>

            ) : (
                <Box className="list-toolbar-secondary list-toolbar-filter">
                    <GridToolbarQuickFilter  sx={{ marginLeft: "auto" }} />
                    {rightButtons.map((v, k) =>
                        <Box key={k}>{v}</Box>
                    )}
                </Box>
            )}

            <Box className="list-toolbar-primary">
                {leftButtons.map((v, k) =>
                    <Box key={k}>{v}</Box>
                )}

                <IconButton onClick={handleSettingsToggle} className="list-toolbar-activator" icon="faEllipsisVertical" />
            </Box>
        </GridToolbarContainer>

    )
}

export default ListToolbar;
