import Person from './Person';

const Module = {
    name: "persons",
    Component: Person,
    defaultRoutePath: "/people",
    menuItem: {
        title: "People",
        href: "/people",
        icon: "faPeople"
    },

    menuItems: {
        "persons": [
        ]
    },
    routes: [
        {
            path: "/people",
            protected: true,
            children: [
                {
                    index: true,
                    path: ":id?",
                    Component: Person,
                }
            ],
        },
    ],
    dataProviders: [
        {
            "name": "persons",
            "endpoint": "/api/persons/person",
        },
    ]
}

export default Module;
