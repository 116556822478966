import Bi from './Bi';

const Module = {
    name: "bis",
    Component: Bi,
    defaultRoutePath: "/bis",
    menuItem: {
        title: "BI Configurations",
        href: "/bis",
        icon: "faChartPieSimpleCircleCurrency"
    },

    menuItems: {
        "bis": [
        ]
    },
    routes: [
        {
            path: "/bis",
            protected: true,
            children: [
                {
                    index: true,
                    path: ":id?",
                    Component: Bi,
                }
            ],
        },
    ],
    dataProviders: [
        {
            "name": "bis",
            "endpoint": "/api/bis/bi",
        },
        // {
        //   "name": "bi",
        //   "endpoint": "/api/bis/bi",
        // },
        // {
        //   "name": "bis",
        //   "endpoint": "/api/bis/bi"
        // }
    ]
}

export default Module;
