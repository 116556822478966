import React, { useEffect } from 'react';

import { Box } from '@mui/joy';
import { KPI, User, List, Text } from '../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../mtska-frontend-data-provider';

const BiViewer = ({ dataProviderName = 'biviewer', showDetail = false, filters = {},
}) => {


    const { items, item, widgets, total, tableConfig, loadAll, formConfig, loadOne } = useDataProvider(dataProviderName);

    useEffect(() => {
        if (loadAll) loadAll()
    }, [])

    return (
        <Box className="module-body">
            <Box className="mtksa-chart-wrapper">
                <User
                    title="Welcome"
                    icon="faPerson"
                    score={widgets.person?.total}>
                </User>
                <KPI
                    title="Report BI"
                    icon="faDashboard"
                    score={total}>
                </KPI>
            </Box>
            <Text level="h3" className="module-body-section-title">I tuoi report</Text>
            <Text level="body-md" className="module-body-section-subtitle">Benvenuto nell'area di lettura dei report prodotti per la tua azienda. Seleziona una voce dall'elenco per accedere alle informazioni a te riservate.</Text>
            
            <List
                dataProviderName={dataProviderName}
                items={items}
                total={total}
                tableConfig={tableConfig}
            />
        </Box>
    )






}

export default BiViewer;


