import React from 'react';
import { RouterProvider, createBrowserRouter, redirect } from 'react-router-dom';
import { Full, Minimal } from '../views';

const Routes = ({ modules = [] }) => {


    const protectedLoader = ({ request }) => {
        if (!localStorage.getItem('token')) {
            let params = new URLSearchParams();
            params.set("from", new URL(request.url).pathname);
            return redirect("/?" + params.toString());
        }
        return null;
    }

    const publicLoader = ({ request }) => {
        if (localStorage.getItem('token')) {
            return redirect(process.env.REACT_APP_AUTH0_DEFAULT_AUTHENTICATED_URL);
        }
        return null;
    }

    const rs = [
        {
            path: "/",
            Component: Full,
            loader: publicLoader
        },
    ];

    modules.map((m, k) => {
        m.routes.map((r, j) => {
            if (r.protected) {
                r.Component = Full
                r.loader = protectedLoader
            } else {
                r.Component = Minimal
                r.loader = publicLoader
            }
            rs.push(r)
        })
    });



    const router = createBrowserRouter(rs);
    return <RouterProvider router={router} />

}

export default Routes;
