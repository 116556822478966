import React from 'react';
import { Box, List, ListItem } from '@mui/joy';

const CellLegalentities = ({ value }) => {
    const limit = 4;
    const length = Object.keys(value).length;
    let more = null;

    if (length > limit) {
        more = <ListItem className={'cell-legalentities-list-item-count cell-legalentities-list-item-count-more'}>
            <span>+{length - limit}</span>
        </ListItem>
    }

    return (
        <Box className={"list-element-cell cell-legalentities"}>
            <List className="cell-legalentities-list">
                {value.map((legalentity, k) => {
                    if (k >= limit) return;
                    return (
                        <ListItem key={k} className={'cell-legalentities-list-item'}>
                            <span className="name" title={legalentity.name}>{legalentity.name.charAt(0)}</span>
                        </ListItem>
                    );
                })}

                {more}
            </List>
        </Box>
    );
}

export default CellLegalentities