

const authHeader = (addContentType = true) => {


    const token = localStorage.getItem('token')
    // headers.append("Authorization", `Bearer ${token}`)
    const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`,
        // "x-auth-token": `Bearer ${token}`,
    }
    // var headers = new Headers()
    // headers.append("Accept", "*/*")
    // headers.append("Access-Control-Allow-Origin", "*")
    // if(addContentType) {
    //     headers.append("Content-Type", "application/json")
    //     headers.append("Accept", "application/json, text/plain, */*")
        

    //     // Accept: application/json, text/plain, */*
    //     // Accept-Encoding: gzip, deflate, br, zstd
    //     // Accept-Language: en-US,en;q=0.9,it;q=0.8,es;q=0.7
    //     // Connection: keep-alive
    //     // Sec-Fetch-Dest: empty
    //     // Sec-Fetch-Mode: cors
    //     // Sec-Fetch-Site: cross-site
       



    // }

    // return authorization header with jwt token
    // const token = store.getState().user?.token;
    

    
    
    return headers;
}

export default authHeader