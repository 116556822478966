/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// import validate from 'validate.js';
import {
  Grid,
  Button,
  TextField,
  Link,
  Typography
} from '@mui/joy';

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  }
};


const LostPassword = props => {
  

  

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const dispatch = useDispatch();

  useEffect(() => {
    // const errors = validate(formState.values, schema);
    const errors = {};

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);


  const handleChange = event => {
    
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleLostPassword = event => {
    event.preventDefault();
    
    const { email } = formState.values;
    if (email) {
      // dispatch(userActions.recover(email));
    }

  };

  const hasError = field => formState.touched[field] && formState.errors[field] ? true : false;
  

  return (
    <div >
      <Grid
        
        container
      >
        
        <Grid
          
          item
          lg={12}
          xs={12}
        >
          <div >
            <div >
              
            </div>
            <div >
              <form
                
                onSubmit={handleLostPassword}
              >
                <Typography
                  
                  variant="h2"
                >
                  Lost Password 
                </Typography>
              
                
               
                <TextField
                  
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <Button
                  
                  color="primary"
                  disabled={!formState.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Recover password
                </Button>
                <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  <Link
                    component={RouterLink}
                    to="/sign-ins"
                    variant="h6"
                  >
                    Back to Sing In!
                  </Link>
                </Typography>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

LostPassword.propTypes = {
  history: PropTypes.object,
  login: PropTypes.object,
};


function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  // login: userActions.login,
  // logout: userActions.logout
};

const connectedLostPassword = connect(mapState, actionCreators)(LostPassword);
export default  connectedLostPassword ;

